import { render, staticRenderFns } from "./MenProfile.vue?vue&type=template&id=471a06f2&scoped=true&"
import script from "./MenProfile.vue?vue&type=script&lang=js&"
export * from "./MenProfile.vue?vue&type=script&lang=js&"
import style0 from "./MenProfile.vue?vue&type=style&index=0&id=471a06f2&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "471a06f2",
  null
  
)

export default component.exports